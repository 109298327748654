// Gatsby Browser API docs: https://www.gatsbyjs.org/docs/browser-apis/
require('bootstrap/dist/css/bootstrap.css')
require('./src/components/Layout/css/index.css')
require('./src/components/Layout/css/prism-okaidia.css')
require('./src/components/Layout/css/b16-tomorrow-dark.css')
require('./src/components/Layout/layout.css')

//const ReactAI  = require('react-appinsights')

exports.onRouteUpdate = ({ location }) => {
  // if (process.env.NODE_ENV === "production") {
  //   // Application Insights JS docs: https://github.com/Microsoft/ApplicationInsights-JS
  //   // React Application Insights docs https://github.com/Azure/react-appinsights

  //   ReactAI.init({instrumentationKey:'8fa0cae4-72d1-46b4-8773-09461a1feaef'});

  //   ReactAI.ai().trackPageView(
  //     location.pathname, /* (optional) page name */
  //     location.pathname /* (optional) page url if available */
  //   );
  // }
}
